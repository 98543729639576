<template>
  <div v-if="currentUser.superAdmin">
    <v-data-table
      :headers="headers"
      :items="shopAdvertisedPosts"
      :options.sync="pagination"
      :server-items-length="totalShopAdvertisedPosts"
      :loading="loading"
      :footer-props="{
        showFirstLastPage: true,
        'items-per-page-options': [10, 20, 50, 100]
      }"
      class="elevation-1"
    >
      <template v-slot:item.shopId="{ item }">
        <td class="text-xs-right">
          {{ item.shopProductPost != null ? item.shopProductPost.shopId : '' }}
        </td>
      </template>
      <template v-slot:item.shopName="{ item }">
        <td class="text-xs-right">
          {{ item.shopProductPost != null ? item.shopProductPost.shopName : '' }}
        </td>
      </template>
      <template v-slot:item.productPicture="{ item }">
        <td class="text-xs-right">
          <v-img
            v-if="
              item.shopProductPost != null &&
                item.shopProductPost.shopProduct != null &&
                item.shopProductPost.shopProduct.media.length > 1
            "
            :src="item.shopProductPost.shopProduct.media[0].fullThumbPath1"
            contain
            max-height="100"
            max-width="100"
            @error="
              item.shopProductPost.shopProduct.media[0].fullThumbPath1 =
                'media/error/imageNotFound.png'
            "
            @click="goToGallery(item.shopProductPost.shopProduct.media)"
          >
            <v-avatar
              v-if="
                item.shopProductPost != null &&
                  item.shopProductPost.shopProduct != null &&
                  item.shopProductPost.shopProduct.media.length > 1
              "
              right
              color="indigo"
              size="22"
              rounded
            >
              <span
                v-if="
                  item.shopProductPost != null &&
                    item.shopProductPost.shopProduct != null &&
                    item.shopProductPost.shopProduct.media.length > 1
                "
                class="white--text"
                >{{ item.shopProductPost.shopProduct.media.length }}</span
              >
            </v-avatar>
          </v-img>
        </td>
      </template>
      <template v-slot:item.productName="{ item }">
        <td class="text-xs-right">
          {{ item.shopProductPost != null ? item.shopProductPost.title : '' }}
        </td>
      </template>
      <template v-slot:item.productDescription="{ item }">
        {{
          item.shopProductPost != null
            ? item.shopProductPost.description.length > 20
              ? item.shopProductPost.description.substring(0, 20)
              : ''
            : ''
        }}
        <v-menu bottom offset-y>
          <template v-slot:activator="{ on }">
            <v-icon
              v-if="item.shopProductPost != null && item.shopProductPost.description.length > 20"
              small
              class="mr-2"
              v-on="on"
              >mdi-dots-horizontal
            </v-icon>
          </template>
          <v-card elevation="2">
            <v-card-text>
              <span class="black--text" v-if="item.shopProductPost != null">{{
                item.shopProductPost.description
              }}</span>
            </v-card-text>
          </v-card>
        </v-menu>
      </template>
      <template v-slot:item.city="{ item }">
        <td class="text-xs-right">
          {{ item.city != null ? item.city.name : '' }}
        </td>
      </template>
      <template v-slot:item.category="{ item }">
        <td class="text-xs-right">
          {{ item.shopCategory != null ? item.shopCategory.name : '' }}
        </td>
      </template>
      <template v-slot:item.published="{ item }">
        <v-switch
          v-model="item.published"
          @click="publishShopAdvertisedPost(item.id, item.published)"
        ></v-switch>
      </template>

      <template v-slot:item.product="{ item }">
        <v-avatar color="indigo" size="26" rounded>
          <v-icon dark @click="goToProductDialog(item.shopProductPost.shopProduct)"
            >mdi-tag-text-outline
          </v-icon>
        </v-avatar>
      </template>

      <template v-slot:body.prepend>
        <tr>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-text-field
              v-model="shopName"
              type="text"
              @keyup.enter="
                page = 1;
                retrieveShopAdvertisedPosts();
              "
            ></v-text-field>
          </td>

          <td>
            <v-menu
              ref="dateRangeMenu"
              v-model="dateRangeMenu"
              :close-on-content-click="false"
              :return-value.sync="dates"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="dates" v-bind="attrs" v-on="on" clearable></v-text-field>
              </template>
              <v-date-picker v-model="dates" range>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="dateRangeMenu = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="
                    $refs.dateRangeMenu.save(dates);
                    retrieveShopAdvertisedPosts();
                  "
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-text-field
              v-model="productName"
              type="text"
              @keyup.enter="
                page = 1;
                retrieveShopAdvertisedPosts();
              "
            ></v-text-field>
          </td>
          <td>
            <v-text-field v-model="productDescription" type="text"></v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="cityName"
              type="text"
              @keyup.enter="
                page = 1;
                retrieveShopAdvertisedPosts();
              "
            ></v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="categoryName"
              type="text"
              @keyup.enter="
                page = 1;
                retrieveShopAdvertisedPosts();
              "
            ></v-text-field>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-select
              :items="postPublishedList"
              v-model="postPublished"
              dense
              hide-details
              @keyup.enter="
                page = 1;
                retrieveShopAdvertisedPosts();
              "
            ></v-select>
          </td>
          <td>
            <v-row>
              <v-col>
                <v-avatar color="indigo" size="30">
                  <v-icon
                    dark
                    @click="
                      page = 1;
                      retrieveShopAdvertisedPosts();
                    "
                    >mdi-magnify
                  </v-icon>
                </v-avatar>
              </v-col>
              <v-col>
                <v-avatar color="grey" size="30">
                  <v-icon
                    dark
                    @click="
                      page = 1;
                      clearSearch();
                    "
                    >mdi-close
                  </v-icon>
                </v-avatar>
              </v-col>
            </v-row>
          </td>
        </tr>
      </template>
    </v-data-table>
    <div class="table-footer-prepend d-flex pl-2 align-center">
      <v-btn
        :loading="downloadingReport"
        :disabled="downloadingReport"
        color="green darken-1"
        class="ma-2 white--text"
        @click="
          loader = 'downloadingReport';
          downloadReport();
        "
      >
        {{ $t('pages.advertisedPosts.apr') }}
        <v-icon right dark>
          mdi-file-excel
        </v-icon>
      </v-btn>
      <v-btn
        :loading="downloadingTotalReport"
        :disabled="downloadingTotalReport"
        color="green darken-1"
        class="ma-2 white--text"
        @click="
          loader = 'downloadingTotalReport';
          downloadTotalReport();
        "
      >
        {{ $t('pages.advertisedPosts.tr') }}
        <v-icon right dark>
          mdi-file-excel
        </v-icon>
      </v-btn>
    </div>
    <ProductDialog
      v-model="showProductDialog"
      @close="showProductDialog = false"
      :shopProduct="shopProduct"
    />
    <GalleryDialog
      v-model="showGalleryDialog"
      @close="showGalleryDialog = false"
      :mediaList="mediaList"
    />
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import ProductDialog from '@/view/pages/ProductDialog';
import GalleryDialog from '@/view/pages/GalleryDialog';
import { mapGetters } from 'vuex';

export default {
  name: 'shop-advertised-post',
  computed: {
    ...mapGetters(['currentUser']),
    name() {
      return this.$i18n.t('menu.shopAdvertisedPosts');
    },
    headers() {
      return [
        {
          text: this.$i18n.t('commonTable.shopId'),
          align: 'start',
          sortable: false,
          value: 'shopId'
        },
        { text: this.$i18n.t('commonTable.shopName'), value: 'shopName', sortable: false },
        { text: this.$i18n.t('commonTable.date'), value: 'date' },
        {
          text: this.$i18n.t('commonTable.productPicture'),
          value: 'productPicture',
          sortable: false
        },
        { text: this.$i18n.t('commonTable.productName'), value: 'productName', sortable: false },
        {
          text: this.$i18n.t('commonTable.productDescription'),
          value: 'productDescription',
          sortable: false
        },
        { text: this.$i18n.t('commonTable.city'), value: 'city', sortable: false },
        { text: this.$i18n.t('commonTable.category'), value: 'category', sortable: false },
        { text: this.$i18n.t('commonTable.price'), value: 'price', width: '6%' },
        {
          text: this.$i18n.t('commonTable.published'),
          value: 'published',
          width: '8%',
          sortable: false
        },
        {
          text: this.$i18n.t('commonTable.product'),
          value: 'product',
          align: 'center',
          width: '8%',
          sortable: false
        }
      ];
    }
  },
  data() {
    return {
      dates: [],
      dateRangeMenu: false,
      shopName: '',
      productName: '',
      productDescription: '',
      cityName: '',
      categoryName: '',
      postPublished: '',
      totalShopAdvertisedPosts: 0,
      shopAdvertisedPosts: [],
      loading: true,
      pagination: {},
      showProductDialog: false,
      shopProduct: null,
      showGalleryDialog: false,
      mediaList: [],
      postPublishedList: ['All', 'No', 'Yes'],
      downloadingReport: false,
      downloadingTotalReport: false,
      errors: []
    };
  },
  components: {
    ProductDialog,
    GalleryDialog
  },
  watch: {
    pagination: {
      handler() {
        this.retrieveShopAdvertisedPosts();
      },
      deep: true
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: `${this.name}: ` }]);
  },
  methods: {
    downloadReport() {
      this.downloadingReport = true;
      const params = this.getRequestParams(
        this.shopName,
        this.dates,
        this.productName,
        this.productDescription,
        this.cityName,
        this.categoryName,
        this.postPublished
      );
      params.responseType = 'blob';
      ApiService.getFileWithParams(`/api/download/excel/advertised-posts-report`, params)
        .then(response => {
          const blob = new Blob([response.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          });
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = 'AdvertisedPosts';
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(error => {
          this.$log.error('Error: ', error);
          this.errored = true;
        })
        .finally(() => (this.downloadingReport = false));
    },
    downloadTotalReport() {
      this.downloadingTotalReport = true;
      const params = this.getRequestParams(
        this.shopName,
        this.dates,
        this.productName,
        this.productDescription,
        this.cityName,
        this.categoryName,
        this.postPublished
      );
      params.responseType = 'blob';
      ApiService.getFileWithParams(`/api/download/excel/advertised-posts-total-report`, params)
        .then(response => {
          const blob = new Blob([response.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          });
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = 'AdvertisedPostsTotal';
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(error => {
          this.$log.error('Error: ', error);
          this.errored = true;
        })
        .finally(() => (this.downloadingTotalReport = false));
    },
    goToGallery(mediaList) {
      this.showGalleryDialog = true;
      this.mediaList = mediaList;
    },
    goToProductDialog(shopProduct) {
      this.showProductDialog = true;
      this.shopProduct = shopProduct;
    },
    publishShopAdvertisedPost(id, publish) {
      this.loading = true;
      ApiService.patch(`api/shop-advertised-posts/${id}`, {
        published: publish
      })
        .then(response => {
          this.$log.debug('Published: ', response.data.published);
          this.retrieveShopAdvertisedPosts();
        })
        .catch(error => {
          this.$log.error('Error: ', error);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    },
    getColor(activated) {
      if (activated) return 'green';
      else return 'red';
    },
    getRequestParams(
      shopName,
      dates,
      productName,
      productDescription,
      cityName,
      categoryName,
      postPublished
    ) {
      let params = {};

      if (shopName) {
        params['shopName'] = shopName;
      }

      if (dates != null && dates[0] != null) {
        params['dateRange'] = dates.join(',');
      }

      if (productName) {
        params['productName'] = productName;
      }

      if (productDescription) {
        params['productDescription'] = productDescription;
      }

      if (cityName) {
        params['cityName'] = cityName;
      }

      if (categoryName) {
        params['categoryName'] = categoryName;
      }

      if (postPublished) {
        params['postPublished'] = postPublished;
      }

      let sort;
      let sortBy = this.pagination.sortBy;
      let sortDesc = this.pagination.sortDesc;
      if (sortBy.length === 1 && sortDesc.length === 1) {
        sort = sortBy[0];
        if (sortDesc[0] === true) sort += ',desc';
      }
      params['sort'] = sort;
      params['page'] = this.pagination.page;
      params['size'] = this.pagination.itemsPerPage;

      return params;
    },
    clearSearch() {
      (this.shopName = ''),
        (this.dates = []),
        (this.productName = ''),
        (this.productDescription = ''),
        (this.cityName = ''),
        (this.categoryName = ''),
        (this.postPublished = '');
    },

    retrieveShopAdvertisedPosts() {
      const params = this.getRequestParams(
        this.shopName,
        this.dates,
        this.productName,
        this.productDescription,
        this.cityName,
        this.categoryName,
        this.postPublished
      );

      return new Promise(resolve => {
        this.loading = true;
        ApiService.query('api/shop-advertised-posts', {
          params
        }).then(response => {
          // JSON responses are automatically parsed.
          this.$log.debug('Shop advertised posts: ', response.data.content);
          this.shopAdvertisedPosts = response.data.content;
          this.totalShopAdvertisedPosts = response.data.totalElements;
          this.totalPages = response.data.totalPages;
          this.loading = false;
          let title = this.totalShopAdvertisedPosts;
          this.$store.dispatch(SET_BREADCRUMB, [{ title: `${this.name}: ` + title }]);
          resolve();
        });
      });
    }
  }
};
</script>

<style>
.v-data-table.v-data-table.v-data-table >>> td {
  font-size: 16px;
}

.v-icon.v-icon {
  font-size: 24px !important;
}

tbody tr:nth-of-type(even) {
  background-color: rgba(236, 237, 237);
}

tbody tr:nth-of-type(odd) {
  background-color: rgb(250, 250, 250);
}

.table-footer-prepend {
  margin-top: -58px;
  height: 58px;
}
</style>
