<template>
  <v-dialog v-model="show" persistent fullscreen>
    <v-card>
    <v-toolbar dark color="primary">
      <v-spacer></v-spacer>
      <v-btn icon dark @click="show = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
      <v-row >
        <v-col
            v-for="media in mediaList"
            :key="media.id"
            class="d-flex child-flex"
            cols="2"
        >
          <v-img
              :src="media.fullThumbPath3"
              contain
              aspect-ratio="1"
              @error="media.fullThumbPath3='media/error/imageNotFound.png'"
              class="grey lighten-2"
          >
            <template v-slot:placeholder>
              <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
              >
              </v-row>
            </template>
          </v-img>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";

export default {
  props: {
    modal: {
      default: false
    },
    mediaList: {
      type: Array
    },
    value: Boolean
  },

  name: "custom-page",
  data() {
    return {
      tabIndex: 0
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Order Info" }]);
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),

    show: {
      get() {
        return this.value;
      },
      set(value) {
        if (!value) {
          this.$emit("input", value);
        }
      }
    }
  }
};
</script>
<style>
</style>
