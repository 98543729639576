var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.currentUser.superAdmin)?_c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.shopAdvertisedPosts,"options":_vm.pagination,"server-items-length":_vm.totalShopAdvertisedPosts,"loading":_vm.loading,"footer-props":{
      showFirstLastPage: true,
      'items-per-page-options': [10, 20, 50, 100]
    }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:"item.shopId",fn:function(ref){
    var item = ref.item;
return [_c('td',{staticClass:"text-xs-right"},[_vm._v(" "+_vm._s(item.shopProductPost != null ? item.shopProductPost.shopId : '')+" ")])]}},{key:"item.shopName",fn:function(ref){
    var item = ref.item;
return [_c('td',{staticClass:"text-xs-right"},[_vm._v(" "+_vm._s(item.shopProductPost != null ? item.shopProductPost.shopName : '')+" ")])]}},{key:"item.productPicture",fn:function(ref){
    var item = ref.item;
return [_c('td',{staticClass:"text-xs-right"},[(
            item.shopProductPost != null &&
              item.shopProductPost.shopProduct != null &&
              item.shopProductPost.shopProduct.media.length > 1
          )?_c('v-img',{attrs:{"src":item.shopProductPost.shopProduct.media[0].fullThumbPath1,"contain":"","max-height":"100","max-width":"100"},on:{"error":function($event){item.shopProductPost.shopProduct.media[0].fullThumbPath1 =
              'media/error/imageNotFound.png'},"click":function($event){return _vm.goToGallery(item.shopProductPost.shopProduct.media)}}},[(
              item.shopProductPost != null &&
                item.shopProductPost.shopProduct != null &&
                item.shopProductPost.shopProduct.media.length > 1
            )?_c('v-avatar',{attrs:{"right":"","color":"indigo","size":"22","rounded":""}},[(
                item.shopProductPost != null &&
                  item.shopProductPost.shopProduct != null &&
                  item.shopProductPost.shopProduct.media.length > 1
              )?_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(item.shopProductPost.shopProduct.media.length))]):_vm._e()]):_vm._e()],1):_vm._e()],1)]}},{key:"item.productName",fn:function(ref){
              var item = ref.item;
return [_c('td',{staticClass:"text-xs-right"},[_vm._v(" "+_vm._s(item.shopProductPost != null ? item.shopProductPost.title : '')+" ")])]}},{key:"item.productDescription",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.shopProductPost != null ? item.shopProductPost.description.length > 20 ? item.shopProductPost.description.substring(0, 20) : '' : '')+" "),_c('v-menu',{attrs:{"bottom":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [(item.shopProductPost != null && item.shopProductPost.description.length > 20)?_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":""}},on),[_vm._v("mdi-dots-horizontal ")]):_vm._e()]}}],null,true)},[_c('v-card',{attrs:{"elevation":"2"}},[_c('v-card-text',[(item.shopProductPost != null)?_c('span',{staticClass:"black--text"},[_vm._v(_vm._s(item.shopProductPost.description))]):_vm._e()])],1)],1)]}},{key:"item.city",fn:function(ref){
              var item = ref.item;
return [_c('td',{staticClass:"text-xs-right"},[_vm._v(" "+_vm._s(item.city != null ? item.city.name : '')+" ")])]}},{key:"item.category",fn:function(ref){
              var item = ref.item;
return [_c('td',{staticClass:"text-xs-right"},[_vm._v(" "+_vm._s(item.shopCategory != null ? item.shopCategory.name : '')+" ")])]}},{key:"item.published",fn:function(ref){
              var item = ref.item;
return [_c('v-switch',{on:{"click":function($event){return _vm.publishShopAdvertisedPost(item.id, item.published)}},model:{value:(item.published),callback:function ($$v) {_vm.$set(item, "published", $$v)},expression:"item.published"}})]}},{key:"item.product",fn:function(ref){
              var item = ref.item;
return [_c('v-avatar',{attrs:{"color":"indigo","size":"26","rounded":""}},[_c('v-icon',{attrs:{"dark":""},on:{"click":function($event){return _vm.goToProductDialog(item.shopProductPost.shopProduct)}}},[_vm._v("mdi-tag-text-outline ")])],1)]}},{key:"body.prepend",fn:function(){return [_c('tr',[_c('td',[_c('v-spacer')],1),_c('td',[_c('v-text-field',{attrs:{"type":"text"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.page = 1;
              _vm.retrieveShopAdvertisedPosts();}},model:{value:(_vm.shopName),callback:function ($$v) {_vm.shopName=$$v},expression:"shopName"}})],1),_c('td',[_c('v-menu',{ref:"dateRangeMenu",attrs:{"close-on-content-click":false,"return-value":_vm.dates,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},on:{"update:returnValue":function($event){_vm.dates=$event},"update:return-value":function($event){_vm.dates=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"clearable":""},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}},'v-text-field',attrs,false),on))]}}],null,false,3580630307),model:{value:(_vm.dateRangeMenu),callback:function ($$v) {_vm.dateRangeMenu=$$v},expression:"dateRangeMenu"}},[_c('v-date-picker',{attrs:{"range":""},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.dateRangeMenu = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.$refs.dateRangeMenu.save(_vm.dates);
                  _vm.retrieveShopAdvertisedPosts();}}},[_vm._v(" OK ")])],1)],1)],1),_c('td',[_c('v-spacer')],1),_c('td',[_c('v-text-field',{attrs:{"type":"text"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.page = 1;
              _vm.retrieveShopAdvertisedPosts();}},model:{value:(_vm.productName),callback:function ($$v) {_vm.productName=$$v},expression:"productName"}})],1),_c('td',[_c('v-text-field',{attrs:{"type":"text"},model:{value:(_vm.productDescription),callback:function ($$v) {_vm.productDescription=$$v},expression:"productDescription"}})],1),_c('td',[_c('v-text-field',{attrs:{"type":"text"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.page = 1;
              _vm.retrieveShopAdvertisedPosts();}},model:{value:(_vm.cityName),callback:function ($$v) {_vm.cityName=$$v},expression:"cityName"}})],1),_c('td',[_c('v-text-field',{attrs:{"type":"text"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.page = 1;
              _vm.retrieveShopAdvertisedPosts();}},model:{value:(_vm.categoryName),callback:function ($$v) {_vm.categoryName=$$v},expression:"categoryName"}})],1),_c('td',[_c('v-spacer')],1),_c('td',[_c('v-select',{attrs:{"items":_vm.postPublishedList,"dense":"","hide-details":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.page = 1;
              _vm.retrieveShopAdvertisedPosts();}},model:{value:(_vm.postPublished),callback:function ($$v) {_vm.postPublished=$$v},expression:"postPublished"}})],1),_c('td',[_c('v-row',[_c('v-col',[_c('v-avatar',{attrs:{"color":"indigo","size":"30"}},[_c('v-icon',{attrs:{"dark":""},on:{"click":function($event){_vm.page = 1;
                    _vm.retrieveShopAdvertisedPosts();}}},[_vm._v("mdi-magnify ")])],1)],1),_c('v-col',[_c('v-avatar',{attrs:{"color":"grey","size":"30"}},[_c('v-icon',{attrs:{"dark":""},on:{"click":function($event){_vm.page = 1;
                    _vm.clearSearch();}}},[_vm._v("mdi-close ")])],1)],1)],1)],1)])]},proxy:true}],null,false,2718509641)}),_c('div',{staticClass:"table-footer-prepend d-flex pl-2 align-center"},[_c('v-btn',{staticClass:"ma-2 white--text",attrs:{"loading":_vm.downloadingReport,"disabled":_vm.downloadingReport,"color":"green darken-1"},on:{"click":function($event){_vm.loader = 'downloadingReport';
        _vm.downloadReport();}}},[_vm._v(" "+_vm._s(_vm.$t('pages.advertisedPosts.apr'))+" "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" mdi-file-excel ")])],1),_c('v-btn',{staticClass:"ma-2 white--text",attrs:{"loading":_vm.downloadingTotalReport,"disabled":_vm.downloadingTotalReport,"color":"green darken-1"},on:{"click":function($event){_vm.loader = 'downloadingTotalReport';
        _vm.downloadTotalReport();}}},[_vm._v(" "+_vm._s(_vm.$t('pages.advertisedPosts.tr'))+" "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" mdi-file-excel ")])],1)],1),_c('ProductDialog',{attrs:{"shopProduct":_vm.shopProduct},on:{"close":function($event){_vm.showProductDialog = false}},model:{value:(_vm.showProductDialog),callback:function ($$v) {_vm.showProductDialog=$$v},expression:"showProductDialog"}}),_c('GalleryDialog',{attrs:{"mediaList":_vm.mediaList},on:{"close":function($event){_vm.showGalleryDialog = false}},model:{value:(_vm.showGalleryDialog),callback:function ($$v) {_vm.showGalleryDialog=$$v},expression:"showGalleryDialog"}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }